.center-dialog-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 538px;
  height: 450px;
  border-radius: 17px;
  border: 1px solid var(--color-primary-yellow);
  background-color: var(--color-primary-dark-grey);
}

.item-name .upload .ar-file {
  width: 196px;
  height: 43px;
  border-radius: 10px;
  border: 0.5px solid #ffad14;
}

.price {
  width: 234px;
}

.rupee-container {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.rupee-container > img {
  width: 12px;
  height: 20px;
  margin-right: -20px;
  z-index: 10;
}

.download-container {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.download-container > img {
  width: 20px;
  height: 16px;
  margin-left: -50px;
  z-index: 10;
}

.container-grad {
  display: flex;
  align-items: center;
  align-self: center;
  margin-bottom: 25px;
  /* margin-left: 20px; */
}

.gradient-2 {
  width: 229px;
  height: 4px;
  background: linear-gradient(to right, #ffad14, #ffffff, #2e2e2e);
  text-align: center;
  line-height: 50px;
  color: black;
}

.common-label-or {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
  margin: 0px 10px 0 10px;
}

.gradient-1 {
  width: 220px;
  height: 4px;
  background: linear-gradient(to right, #2e2e2e, #ffffff, #ffad14);
  text-align: center;
  line-height: 50px;
  color: black;
}

.upload-files {
  text-align: center;
  padding-bottom: 5px;
}

.upload-files > div:nth-child(1) {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.upload-files > div:nth-child(2) {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #ffad14;
}

.finish-btn {
  margin-left: 80%;
}
