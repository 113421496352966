@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;800&display=swap");

:root {
  --color-bg-primary: #1d1d1d;
  --color-primary-grey: #3c3c3c;
  --color-primary-dark-grey: #2e2e2e;
  --color-primary-yellow: #ffad14;
  --color-white: #fff;
  --color-secondary-white: #dadada;
  --color-black: #000;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Source Sans 3", sans-serif;
  line-height: 1.7;
  color: #777;
  padding: 3rem;
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: var(--color-bg-primary);
}

.flex-r {
  display: flex;
  flex-direction: row;
}

.flex-c {
  display: flex;
  flex-direction: column;
}

.align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.justify-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.self-c {
  align-self: center;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 4;
}
.text-c {
  text-align: center;
}

.w-1256 {
  width: 1256px;
}
