.restro-header {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
  margin-bottom: 20px;
}

.restro-heading {
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
}

@media only screen and (max-width: 13.9in) {
  .restro-header {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .restro-heading {
    font-size: 26px;
  }
}

/* Styles for screens between 13 inches and 14 inches */
/* @media only screen and (min-width: 13in) and (max-width: 14in) {
  .restro-header {
    margin-top: 50px;
    margin-bottom: 15px;
  }

  .restro-heading {
    font-size: 26px;
  }
} */
