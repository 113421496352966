.Home {
  display: flex;
  justify-content: space-evenly;
  padding-top: 14px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 5vw;
  height: 94vh;
  border-radius: 15px;
  background-color: var(--color-primary-grey);
}

.sidebar > div {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-top: 120px;
  /* align-self: center; */
}

.icon-sidebar {
  align-self: center;
  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
}

.menu {
  margin-bottom: 20px;
  padding: 8px;
}

.user {
  margin-bottom: 50px;
}

.main-container {
  width: 90%;
  align-self: center;
  /* margin: auto; */
}

.main-container > h2 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  color: var(--color-white);
  margin: 0 0 8px 0;
}

.main-container > h1 {
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
  margin: 5px 0 40px 5px;
}

.main-container-head {
  width: 100%;
  height: 5vw;
  display: flex;
  align-items: center;
  background-color: var(--color-primary-grey);
}

.main-container-heading {
  font-family: Source Sans 3;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  /* margin-top: 10px; */
  color: var(--color-white);
}

.details-container {
  width: 100%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px 0;
}

.user-details {
  /* align-self: center; */
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: var(--color-white);
}

.line-vertical {
  width: 100%;

  border: 1px solid #eff1f766;
}

.pagenation-num-2 {
  position: relative;
}

.pagenation-num-2 {
  position: absolute;
  left: 80%;
  margin-top: 30px;
}
