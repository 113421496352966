.custom-btn {
  display: flex;
  align-items: center;

  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  padding: 9px 20px 9px 20px;
  border-radius: 3px;
  border-width: 0;
  gap: 10px;

  background-color: var(--color-primary-yellow);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
  .custom-btn {
    font-size: 9px;
    padding: 4px 10px 4px 10px;
  }
}
