.half-line-gradient {
  height: 4px;
  margin: 10px 5px;
  background: linear-gradient(
    to right,
    var(--color-primary-yellow) 20%,
    var(--color-white) 30%,
    #2e2e2e 70%
  );
}

.input-design {
  height: 43px;
  border-radius: 10px;
  border: 0.5px solid var(--color-primary-yellow);
  background-color: #2e2e2e;
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 5px 15px;
  margin: 5px 15px 10px 0;
}

.name-input {
  width: 193px;
}

.email-input {
  width: 242px;
}

.number-input {
  width: 138px;
}

.address-input {
  width: 379px;
}

.pincode-input {
  width: 206px;
}

.common-label {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-white);
  margin: 10px 0 0 10px;
}

.input-container {
  margin-bottom: 25px;
  margin-top: 20px;
}
