.ARMenu {
  display: flex;
  flex-direction: column;
}

.ARMenu-head {
  display: flex;
  justify-content: space-between;
}

.ar-header {
  margin-left: 5px;
}

.ar-header > h3 {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  color: var(--color-white);
}

.ar-ratings {
  background-color: #1d1d1d;
  padding: 5px 15px;
  width: 86px;
  height: 22px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--color-white);
}

.location-name {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px 10px;
  color: var(--color-white);
}

.gradient-line {
  width: 520px;
  margin: 6px 0px;
  height: 4px;
  background: linear-gradient(
    to right,
    var(--color-primary-yellow) 40%,
    var(--color-white),
    var(--color-primary-dark-grey)
  );
}

.counter-header {
  display: flex;
  justify-content: space-between;
  /* width: 629px; */
  height: 34px;
  border-radius: 6px;
  background-color: #3c3c3c;
  margin: 10px 0px;
  padding: 10px 20px;
}

.counter-header > p {
  /* flex: 1; */
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-white);
}

.item-list {
  display: flex;
  justify-content: space-between;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.07em;
  text-align: center;
  /* padding: 5px 5px; */
  color: var(--color-white);
  margin: 10px 0px;
  /* align-self: flex-start; */
}

.item-list > div {
  flex: 1;
  /* text-wrap: wrap; */
}

.grey-gradient {
  margin: 6px 0px;
  height: 1px;
  background-color: #eff1f733;
}

.pagenation-num {
  align-self: flex-end;
  /* padding-top: 5px; */
}
