.orders-list {
  justify-content: space-evenly;
}

.mob {
  margin-left: 10px;
  margin-bottom: 2px;
}

.date {
  height: 0;
}

.grad-order-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.grad-order-div > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.grad-order-div > div:nth-child(2) > img {
  width: 21px;
  height: 16px;
  margin-left: 8px;
}
